import React from "react";
import "./about.scss";
import bg from "./cafe-scape.png";

const About = () => {
  return (
    <section id="about" className="about">
      <div className="container">
        <h1>
          <h1 className="aboutHeader">퍼리인어스란</h1>
        </h1>
        <div className="row">
          <div className="aboutContainer">
            <div
              style={{ backgroundImage: `url(${bg})` }}
              className="aboutContainerImg bgImg"
            ></div>

            <div className="aboutContainerDetails">
              <h1>
                <span className="colorGrey">특별하고</span> 맛있는 추억을<span className="colorGrey"> </span>
              </h1>
              <p>
                퍼슈트와 함께 달콤한 디저트를 먹으며 추억을 만드세요
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
