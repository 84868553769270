import React from "react";
import Form from "./Form/Form";

import {TiLocationOutline, TiSocialYoutube} from 'react-icons/ti';
import { FaMobileAlt } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import { BsChatSquare } from 'react-icons/bs';
import { AiFillFacebook } from 'react-icons/ai';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { FaInstagram } from 'react-icons/fa';
import "./contact.scss";
import {FiTwitter} from "react-icons/all";

const Contact = () => {
  return (
    <section id="contact" className="contact">
      <div className="container">
        <h1 className="contactHeader">Contact us</h1>
        <div className="contactAddress">
          <div
              className="contactAddressDetails"
              onClick={() => window.open('https://www.youtube.com/@FurryInUs', '_blank')}>
            <h2><TiSocialYoutube /></h2>
            <h1>YouTube</h1>
          </div>

          {/*<div className="contactAddressDetails">*/}
          {/*  <h2><FaMobileAlt /></h2>*/}
          {/*  <h1>phone</h1>*/}
          {/*  <p>000-111-222-333</p>*/}
          {/*</div>*/}
          <div className="contactAddressDetails">
            <h2><AiOutlineMail /></h2>
            <h1>Email</h1>
            <p>furryinus@gmail.com</p>
          </div>

          <div
              className="contactAddressDetails"
              onClick={() => window.open('https://twitter.com/furryinus', '_blank')}
          >
            <h2><FiTwitter /></h2>
            <h1>X[Twitter]</h1>
          </div>
        </div>

        {/*<Form />*/}
      </div>
    </section>
  );
};

export default Contact;
