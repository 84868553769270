import React from "react";
import "./upcoming.scss";
import bg from "./poster_1.png";

const Upcoming = () => {
  return (
      <section id="upcoming" className="upcoming">
        <div className="container">
            <h1 className="upcomingHeader">다음 행사 정보</h1>
          <div className="row">
            <div className="upcomingContainer">
                <div className="upcomingContainerDetails">
                    <h1>
                        <span className="colorPrimary">카페에서</span> 용났다<span className="colorGrey"> </span>
                    </h1>
                    <p>
                        24년 1월 20일 단 하루 오픈!
                    </p>
                    <p>

                    </p>
                </div>
              <div
                  style={{ backgroundImage: `url(${bg})` }}
                  className="upcomingContainerImg bgImg"
              ></div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default Upcoming;
