import React, {useEffect, useState} from "react";

import "./home.scss";

const Home = () => {
    const [videoUrl, setVideoUrl] = useState('');

    useEffect(() => {
        // Fetch the pre-signed URL from your Lambda function
        fetch('https://exyuxh8s67.execute-api.ap-northeast-2.amazonaws.com/prd')
            .then(response => response.json())
            .then(data => {
                setVideoUrl(data.body.url);
            })
            .catch(error => {
                console.error('Error fetching video URL:', error);
            });
    }, []);  // The empty dependency array ensures this effect runs once when the component mounts.

    return (
    <section id="home" className="home" style={{ width: 'auto', height: '600px' }}>
      <video
          key={videoUrl}
        className="homeVideoIntro"
        autoPlay
        playsInline
        muted
        loop
      >
        <source src={videoUrl} type="video/mp4"/>
        Your browser is not supported
      </video>
      <div className="homeDetails">
        <div className="container">
          <div className="row">
            <div className="slogan">
                <img src="assets/logo.png" alt="logo" style={{ width: '400px', height: 'auto' }}/>
              <p><b>행복한 시간을 선사드립니다</b></p>
              {/*<div>*/}
              {/*  <a href="https://www.foodpanda.com.bd/"><button>Order Now</button></a>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
